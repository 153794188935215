.container {
}

.content {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1002;
  outline: 0;
  padding: 20px;
}

.main_content {
  padding-top: 10px;
  padding-bottom: 10px;
  min-height: 100px;
}

.modal_header {
  border-bottom: 1px solid var(--color-gray-pale);
}

.modal_footer {
  border-top: 1px solid var(--color-gray-pale);
  margin-left: -2px;
  margin-right: -2px;
  padding-top: 20px;
  text-align: right;
}

.modal_title {
  font-size: 18px;
  font-weight: var(--font-weight-medium);
  color: var(--color-gray-medium);
  position: relative;
  top: -7px;
}

.inner {
  box-sizing: border-box;
  padding: 20px 30px;
  position: relative;
  top: 100px;
  margin: auto;
  padding-bottom: 24px;
  max-width: 520px;
  background: white;
  margin-bottom: 100px;
}

.close_modal {
  color: var(--color-primary);
  cursor: pointer;
  position: absolute;
  top: 8px;
  right: 17px;
  font-size: 21px;
}

.ghost {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1001;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.45);
}
