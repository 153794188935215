.container {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 55px;
  background: white;
  z-index: 1001;
  display: flex;
  justify-content: space-between;
  padding: 10px 20px;
  border-bottom: 1px solid var(--color-gray-pale);
}

.logo {
  color: var(--color-gray-light);
  font-size: 28px;
  font-weight: var(--font-weight-light);
  position: relative;
  top: -4px;
  left: 31px;
}

.icons {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.icon_dropdown_container {
  position: relative;
}

.drop_down {
  background: white;
  padding: 20px;
  position: absolute;
  top: -5px;
  right: 8px;
  box-shadow: -7px 7px 20px 0 #515c6f52;
  z-index: 2000;
}

.drop_down_ghost {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: transparent;
}

.drop_down_list {
  position: relative;
}

.drop_down_close {
  position: absolute;
  top: -16px;
  right: -8px;
  color: #ff6868;
  cursor: pointer;
}
