.inputWrapper {
  position: relative;
}

.inputIcon {
  position: absolute;
  top: 10px;
  right: 15px;
  color: var(--color-primary);
  font-size: 21px;
  cursor: pointer;
}

.inputPlusIcon {
  position: absolute;
  top: 1px;
  right: 3px;
  color: var(--color-primary);
  font-size: 32px;
  cursor: pointer;
}

.inputIcon i {
}
