.summary_score_section {
  line-height: 2;
  margin-bottom: 20px;
}

.row {
  display: flex;
  justify-content: space-between;
  color: var(--color-gray-medium);
}

.row span {
  display: flex;
  flex-direction: column;
}

.summary_row {
  color: var(--color-gray-dark);
}

.row_score {
  color: var(--color-turquoise);
}

.summary_row .row_score {
  color: var(--color-orange);
  font-weight: var(--font-weight-bold);
}

.header {
  border-bottom: 1px solid var(--color-gray-pale);
  padding-bottom: 20px;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
}

.header_title {
  font-size: 28px;
  font-weight: var(--font-weight-regular);
  color: var(--color-secondary);
}

.header_details {
  text-align: right;
}

.header_details_small {
  font-size: 12px;
  color: var(--color-gray-medium);
}

.header_details_regular {
  font-size: 14px;
}

.question_each {
  font-weight: var(--font-weight-regular);
}

.question_each_name {
}

.question_answer {
  color: var(--color-primary);
}

.question_comments {
  background: var(--color-message-bg);
  display: inline-block;
  padding: 2px 12px;
  border-radius: 7px;
  white-space: pre-wrap;
  font-size: 14px;
  margin-bottom: 30px;
  margin-top: 10px;
  line-height: 1.8;
  font-weight: 400;
  color: var(--color-gray-dark);
}

.no_answer {
  color: var(--color-gray-medium);
}

.small_traffic_light {
  width: 20px;
  height: 20px;
  border-radius: 100%;
  display: inline-block;
  position: relative;
  top: 6px;
  margin-right: 6px;
}

.link_style {
  cursor: pointer;
  display: inline-block;
  color: var(--color-primary);
  font-size: 20px;
  position: relative;
  top: -2px;
  margin-left: 7px;
}

.delete_link_style {
  cursor: pointer;
  color: var(--color-primary);
  font-size: 12px;
  text-align: right;
  padding-top: 75px;
}
