.container {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: white;
  -webkit-transition: background 1s ease-in-out;
  transition: background 1s ease-in-out;
}

.container h1 {
  color: white;
  margin: auto;
  font-size: 63px;
}

.subline {
  font-style: italic;
}

.login_class {
  position: absolute;
  right: 20px;
  top: 10px;
  color: white;
  font-size: 48px;
  cursor: pointer;
}

.about_section {
  max-width: 680px;
  margin: auto;
  text-align: left;
}

.about_section h1 {
}

.about_section p {
  color: white;
  font-size: 18px;
  margin-top: 50px;
  line-height: 2;
}
