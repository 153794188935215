.container {
  width: 100%;
}

.content {
}

.container td {
  padding: 10px 10px;
  position: relative;
}

.container td:first-child {
  padding-left: 0;
}

.container td:last-child {
  padding-right: 0;
}

.container thead td {
  padding: 12px 12px;
}

.container thead {
  font-size: 13px;
  color: var(--color-gray-medium);
  font-weight: var(--font-weight-normal);
  text-transform: uppercase;
}

.container tbody {
  font-size: 15px;
}

.container tbody tr {
  border-top: 1px solid var(--color-gray-pale);
}

.container tbody tr:last-child {
  /*border-bottom: 1px solid var(--color-gray-pale);*/
}

.no_head tbody tr:first-child {
  border-top: none;
}

.no_margin {
  margin: 0;
}

.sort_icon {
  margin-left: 4px;
  cursor: pointer;
  font-size: 14px;
}

@media screen and (max-width: 600px) {
  .responsive_sm {
    display: none;
  }
}

@media screen and (max-width: 900px) {
  .responsive_md {
    display: none;
  }
}

@media screen and (max-width: 1200px) {
  .responsive_lg {
    display: none;
  }
}
