.section_style {
  padding-bottom: 50px;
  padding-top: 50px;
  position: relative;
  text-align: center;
}

.input_score {
  position: absolute;
  width: 100%;
  bottom: 10px;
  font-size: 12px;
  font-weight: 300;
}

.input_score label {
  font-size: 12px !important;
  color: #377f8f !important;
}

.input_score input {
  display: inline-block !important;
  width: 25px;
}

.delete_question {
  position: absolute;
  bottom: 7px;
  left: 0px;
  font-size: 10px;
  color: var(--color-primary);
  cursor: pointer;
}
