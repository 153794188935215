.button_container {
  top: auto;
  bottom: 0px;
  right: 10px;
  position: absolute;
  z-index: 100;
  font-size: 15px;
  line-height: 2.7;
}

.dropdown_title {
  text-transform: uppercase;
  color: var(--color-gray-medium);
  font-size: 12px;
}

.open_button input {
  right: 5px;
  top: 10px;
  border: none;
  font-size: 14px;
  cursor: pointer;
  height: 34px;
  outline: none;
  width: 30px;
  opacity: 1;
  background: none;
}

.open_button i {
  position: absolute;
  top: 0;
  right: 0;
  color: var(--color-primary);
  z-index: 200;
  font-size: 31px;
  width: 100%;
  height: 100%;
  cursor: pointer;
  pointer-events: none;
}

.dropdown_container {
  position: absolute;
  background: white;
  /*border: 1px solid;*/
  z-index: 300;
  top: -9px;
  padding: 15px;
  right: -10px;
  width: 275px;
  box-shadow: -7px 7px 20px 0 #515c6f52;
  padding-left: 20px;
}

.dropdown_inner {
  padding-top: 10px;
}

.dropdown_group {
}

.dropdown_group_header {
  display: flex;
  justify-content: space-between;
  font-size: 15px;
  line-height: 2.7;
}

.dropdown_group_title {
  color: var(--color-gray-dark);
}

.dropdown_group_list {
  color: var(--color-gray-medium);
  /*display: flex;  */
}

.dropdown_group_list_item {
  position: relative;
}

.dropdown_group_tag_name {
  padding-left: 15px;
}

.dropdown_group_tag_kill {
  position: absolute;
  color: var(--color-primary);
  cursor: pointer;
}

.dropdown_list_container {
  /*line-height: 2.5;*/
}

.dropdown_group_item {
  color: var(--color-gray-medium);
  display: flex;

  font-size: 15px;
  line-height: 2.7;
}

.dropdown_group_item_check {
  font-size: 15px;
  line-height: 2.7;
}

.dropdown_group_item_check label {
  cursor: pointer;
}

.dropdown_group_item_check input {
  position: relative;
  margin-right: 10px;
  top: 1px;
}

.dropdown_group_item_kill {
}

.dropdown_close {
  position: absolute;
  top: 8px;
  right: 14px;
  color: var(--color-primary);
  z-index: 400;
  font-size: 31px;
  cursor: pointer;
  line-height: 1;
}

.tag_each {
  position: relative;
  padding-right: 16px;
}

.tag_name {
}

.tag_kill {
  position: absolute;
  top: 0;
  right: 0;
  color: var(--color-gray-medium);
  cursor: pointer;
}
