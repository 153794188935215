.sidebar_container {
  padding-top: 20px;
  padding-left: 20px;
  user-select: none;
  background: white;
  position: absolute;
  left: 0;
  top: 53px;
  min-height: 100vh;
  width: 200px;
  z-index: 1000;
}

.sidebar_container:before {
  content: "";
  width: inherit;
  position: fixed;
  height: 100vh;
  background: white;
  left: 0;
  top: 0;
  z-index: -1;
}

.menu_container {
  height: 100%;
  border-right: 0 !important;
  background: white;
}
