.list_star {
  position: relative;
  left: 6px;
  color: var(--color-gray-light);
  font-size: 18px;
  cursor: pointer;
}

.average_score {
  background: #ffaf52;
  display: inline-block;
  border-radius: 100%;
  width: 26px;
  height: 26px;
  text-align: center;
  color: white;
  font-size: 13px;
}

.average_score span {
  position: relative;
  top: 3px;
  font-size: 12px;
}

.date_style {
  font-size: 12px;
}

.void_list {
  padding-bottom: 25px;
  padding-top: 25px;
  text-align: center;
  line-height: 2;
  color: var(--color-gray-medium);
}

.void_list_label {
}

.void_list_icon {
  font-size: 68px;
  color: var(--color-gray-pale);
}

.pre_space {
  font-size: 12px;
  white-space: pre;
}

.max_width_200 {
  max-width: 200px;
}

.funnel_tag {
  background: #dff3f1;
}

.funnel_tag i {
  color: white;
}

.small_text_flex {
  display: flex;
  justify-content: space-between;
}

.clear_filters {
  font-size: 12px;
  color: var(--color-primary);
  cursor: pointer;
}

.counter {
  /*text-align: right;*/
  font-size: 12px;
  color: var(--color-secondary);
}

.shortcuts_list {
  padding-bottom: 15px;
}

.shortcuts_list li {
  line-height: 2;
  font-size: 14px;
  color: var(--color-secondary);
  text-decoration: underline;
  padding-left: 4px;
  cursor: pointer;
}

.shortcuts_list_footer {
  display: flex;
  justify-content: space-between;
}

.spinner_class {
  position: relative;
  height: 100%;
  font-size: 46px;
  text-align: center;
  padding-top: 9px;
  color: var(--color-gray-light);
}
