.container {
  padding: 15px;
  border: 1px solid #eaeaea;
  padding-bottom: 0px;
  position: relative;
}

.event_toggle_button {
  cursor: pointer;
  position: absolute;
  right: 0px;
  font-size: 12px;
  top: -20px;
  color: #306f7a;
}

.log_feed {
  /*background: white;*/
}

.log_feed_item {
  font-size: 14px;
  margin-bottom: 15px;
  line-height: 1.8;
}

.log_feed_byline {
}

.name {
  font-weight: 600;
  color: #2f6e7b;
}

.date {
  color: #999;
  font-weight: 300;
}

.log_feed_text {
  white-space: pre-wrap;
}

.log_feed_type_SUBJECTIVE_SCORE {
  background: var(--color-message-bg);
  display: inline-block;
  padding: 4px 12px;
  border-radius: 7px;
}

.log_item_edited {
  color: #999;
  font-style: italic;
}

.empty_message {
  opacity: 0.2;
}
