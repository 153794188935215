.container {
  border-radius: 20px;
  color: var(--color-gray-dark);
  background: var(--color-gray-pale);
  padding: 3px 12px;
  display: inline-block;
  font-size: 12px;
  margin: 2px;
}

.content {
  /*white-space: nowrap;*/
}

.active_tag {
  background: var(--color-primary);
  color: white;
}

.button_tag {
  cursor: pointer;
}
