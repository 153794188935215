.verified_phone_number {
  font-size: 10px;
  color: var(--color-turquoise);
  position: relative;
  text-align: right;
  top: -6px;
  right: 5px;
}

.verify_title {
  position: relative;
  padding-bottom: 10px;
  padding-top: 4px;
}

.get_new_code {
  position: absolute;
  font-size: 12px;
  cursor: pointer;
  color: var(--color-primary);
  bottom: 18px;
}

.new_code {
  position: absolute;
  font-size: 12px;
  cursor: pointer;
  color: var(--color-secondary);
  bottom: 18px;
}

.success_message {
  padding-bottom: 16px;
  font-size: 16px;
  color: var(--color-secondary);
  line-height: 1.5;
}
