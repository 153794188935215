.rootContainer {
  padding: 30px;
  max-width: 700px;
  margin: 0 auto;
  position: relative;
  min-height: 80vh;
}

@media (max-width: 1100px) {
  .rootContainer {
    max-width: 800px;
  }
}

@media (max-width: 800px) {
  .rootContainer {
    max-width: 500px;
  }
}
