.container {
  border-bottom: 1px solid var(--color-gray-pale);
}

.content {
  padding: 10px;
  background: white;
  margin: 2px;
  padding-bottom: 15px;
}

.footer {
  position: relative;
}

.filter_star {
}

.filter_star {
  font-size: 18px;
  cursor: pointer;
}

.filter_icon_container {
  cursor: pointer;
  cursor: pointer;
}

.filter_icon_container {
  cursor: pointer;
}

.filter_icon {
  color: var(--color-primary);
  font-size: 30px;
}

.tag_list {
}

.tag_each {
  position: relative;
  padding-right: 16px;
}

.tag_name {
}

.tag_kill {
  position: absolute;
  top: 0;
  right: 0;
  color: var(--color-gray-medium);
  cursor: pointer;
}

.filter_container {
  display: flex;
}

.filter_content {
  background: white;
  margin: 2px;
  padding: 5px 17px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.funnel_tag_container {
  text-align: center;
  position: relative;
}

.funnel_tag {
  background: var(--color-gray-light);
  color: white;
  margin: auto;
  margin-bottom: 2px;
  cursor: pointer;
}

.funnel_tag_active {
  background: var(--color-primary);
}
