.tag_list {
  margin-left: -2px;
  margin-right: -2px;
}

.tag_list div {
  margin: 2px;
}

.question_footer {
  position: relative;
  text-align: right;
  padding-bottom: 10px;
}

.hr {
  border: none;
  border-top: 1px solid var(--color-gray-pale);
  margin: 15px 0px;
}

.list_order {
  position: relative;
  display: inline-block;
  font-size: 25px;
  cursor: pointer;
}

.list_order_button {
  color: var(--color-primary);
}

.order_up {
}

.order_down {
}

.option_dashed_container {
  border: 2px dashed var(--color-gray-light);
  padding: 7px 115px 7px 35px;
  position: relative;
  margin-bottom: 10px;
}

.option_radio_check {
  left: 10px;
  top: 10px;
  position: absolute;
  width: 17px;
  height: 17px;
  padding: 0px;
  margin: 0px;
}

.option_name {
  padding-left: 5px;
}

.option_right_container {
  position: absolute;
  display: flex;
  justify-content: space-between;
  right: 12px;
  top: 6px;
}

.option_score_container {
  font-size: 12px;
  color: var(--color-gray-medium);
  position: relative;
  top: 3px;
  user-select: none;
}

.option_score_toggler {
  position: absolute;
  left: -15px;
  top: -4px;
  padding: 0px;
  height: 100%;
  width: 10px;
}
.option_score_toggle_up {
  position: absolute;
  cursor: pointer;
  top: 0;
}
.option_score_toggle_down {
  position: absolute;
  cursor: pointer;
  bottom: 0;
}

.option_delete_container {
  font-size: 18px;
  cursor: pointer;
  padding-left: 15px;
  color: var(--color-primary);
}

.option_traffic_light_points {
  text-align: center;
  font-size: 12px;
  color: var(--color-gray-medium);
  position: relative;
  top: -7px;
}

.option_save {
  position: absolute;
  right: 12px;
  top: 9px;
  color: var(--color-primary);
  font-size: 12px;
  cursor: pointer;
}
